(function () {

    document.addEventListener("DOMContentLoaded", () => {
        const heroSliderEl = document.getElementById('hero-slider');

        var heroSlider = new Swiper(heroSliderEl, {
            slidesPerView: 1,
            speed: 750,
            spaceBetween: 60,
            pagination: {
                el: ".hero-pagination",
                clickable: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            breakpoints: {
                768: {
                    speed: 1000,
                }
            }
        });

    });
}());